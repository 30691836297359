<template>
  <div class="assoUser bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1"></Button>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 20rem"
          placeholder="输入主用户或者关联用户姓名进行查找"
          class="el-inline mr-1"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="handleSearch"></Button>
        </i-input>
        <Button type="default" size="large" class="mr-1">批量导出</Button>
      </i-col>
      <i-col span="24">
        <Table
          border
          :loading="searching"
          context-menu
          show-context-menu
          ref="assoUserTable"
          :columns="tColumns"
          :data="tData"
          class="mt-1"
        >
          <div slot="contextMenu">
            <DropdownItem @click.native="assessmentRecord">测评档案</DropdownItem>
            <!-- <DropdownItem @click.native="consultRecord">咨询档案</DropdownItem> -->
          </div>
          <div slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 0.25rem" @click="show(row)">详情</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>

    <modal
      v-model="draw"
      footer-hide
      title="用户详情"
      width="500"
      :mask-closable="true"
      :closable="true"
    >
      <Form :model="relationModel">
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="用户姓名" label-position="left">
              <Input size="large" v-model="relationModel.relationName" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="性别" label-position="left">
              <Input size="large" v-model="relationModel.gender == 1 ? '男' : '女'" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="年龄" label-position="left">
              <Input size="large" v-model="relationModel.birthday" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="婚姻状况" label-position="left">
              <Input size="large" v-model="relationModel.maritalName" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="教育程度" label-position="left">
              <Input size="large" v-model="relationModel.educationName" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="职业信息" label-position="left">
              <Input size="large" v-model="relationModel.professName" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="所属行业" label-position="left">
              <Input size="large" v-model="relationModel.industryName" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="主用户" label-position="left">
              <Input size="large" v-model="relationModel.parentName" />
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </modal>
  </div>
</template>

<script>
export default {
  name: "assoUser",
  data() {
    return {
      relationModel: {},
      draw: false,
      searching: true,
      tData: [],
      inputValue: "",
      selectValue: "usersName",
      isDetach: false,
      theight: window.innerHeight - 175,
      recordTotal: 0,
      pageSize: 20,
      pageNum: 1,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "用户编号",
          key: "userId",
          minWidth: 50,
        },
        {
          title: "姓名",
          key: "relationName",
          minWidth: 80,
        },
        {
          title: "性别",
          key: "gender",
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.gender));
          },
          minWidth: 30,
        },
        {
          title: "年龄",
          key: "age",
          render: (h, params) => {
            let age = this.myjs.getAge(params.row.birthday, new Date());
            return h("span", age);
          },
          minWidth: 50,
          sortable: true,
        },
        {
          title: "婚姻",
          key: "maritalName",
          minWidth: 50,
        },
        {
          title: "学历",
          key: "educationName",
          minWidth: 50,
          sortable: true,
        },
        {
          title: "职业",
          key: "professName",
          minWidth: 100,
        },
        // {
        //   title: "单位",
        //   key: "unitsName",
        //   minWidth: 150,
        //   tooltip: true,
        // },
        {
          title: "主用户",
          key: "parentName",
          minWidth: 80,
        },
        {
          title: "注册时间",
          key: "registerTime",
          width: 120,
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.registerTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        // {
        //   title: "操作",
        //   slot: "action",
        //   width: 57,
        // },
      ],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getRelationUserList();
  },
  methods: {
    show(row) {
      this.relationModel = row;
      this.draw = true;
    },
    changeSize(e) {
      this.pageSize = e;
      this.getRelationUserList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getRelationUserList();
    },
    assessmentRecord() {},
    consultRecord() {},
    handleSearch() {
      this.pageNum = 1;
      this.getRelationUserList();
    },
    getRelationUserList() {
      this.$get("Customer/GetRelationUserList", {
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
